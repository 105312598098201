import React from 'react'
import { Form, Container } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const InputField = ({fieldClass, labelClass, type, name, value, required, placeholder, handlechange, pattern, label, grpmd, grpCls, anchorLink, inputText}) => (
  <Form.Group as={Col} md={grpmd} controlId={"validation" + name} className={grpCls}>
    { label
      ? <Form.Label className={labelClass}>{label}{required ? '*' : ''}</Form.Label>
      : ''
    }

    {
      inputText ? (
        <div className="form-control">
          <span className="inputTextCls">{inputText}</span>
          <Form.Control
            className={fieldClass}
            required={required}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={handlechange}
            pattern={pattern}
            autoComplete={false}
          />

          {anchorLink && anchorLink}
        </div>
      ) : (
        <>
          <Form.Control
              className={fieldClass}
              required={required}
              type={type}
              name={name}
              value={value}
              placeholder={placeholder}
              onChange={handlechange}
              pattern={pattern}
              autoComplete={false}
            />

          {anchorLink && anchorLink}
        </>
      )
    }
    
    
    
  </Form.Group>
);

export default InputField;