import React, {useEffect, useState} from "react"
import { Link, navigate } from "gatsby"
import LoginPage from "./login"
import { handleLogin, isBrowser, setUser, getUser, isLoggedIn } from "../services/auth"
import { useContainerContext } from "../store/ContainerContext";


const IndexPage = () => {
  const [isAuth, setIsAuth] = useState(isLoggedIn());

  if(isAuth){
    //console.log('isAuth', isAuth);
    if (isBrowser()) {
      navigate(`/app-dashboard`);
    }
  } else{
    if (isBrowser()) {
      navigate(`/`);
    }
  }

  return(
    <LoginPage />
  )
}

export default IndexPage
