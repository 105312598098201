import React, {useState} from "react"
import {Form, Button } from 'react-bootstrap';
import { Link, navigate } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import  "../assests/scss/pages/login.scss";
import LogoImg from "../assests/images/logo.svg";
import SEO from "../components/seo"
import { handleLogin, isBrowser, setUser, getUser, isLoggedIn } from "../services/auth"

import InputField from "../components/forms/elements/input"
import ButtonField from "../components/forms/elements/button"

import { useContainerContext } from "../store/ContainerContext";

const LoginPage = () => {
  const context = useContainerContext();
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [error, setError] = useState()
  const [isAuth, setIsAuth] = useState(isLoggedIn())
  const myRef = React.createRef();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [userData, setUserData] = useState('');  

  const fields = [
    {
      element: "config",
      formname: "Login",
      error_text: "Highlighted fields are required | invalid",
      success_text: "",
    },
    {
      grpmd: "12",
      label: "",
      placeholder: "Email Address",
      name: "username",
      type: "email",
      element: "input",
      required: true,
      fieldClass: "",
      labelClass: "content_b-18",
      grpCls: ""
    },
    {
      grpmd: "12",
      label: "",
      placeholder: "Password",
      name: "password",
      type: "password",
      element: "input",
      required: true,
      fieldClass: "",
      labelClass: "content_b-18",
      grpCls: "pass"
    },
    {
      name: "Submit",
      type: "submit",
      element: "button",
      value: "Login",
      class: "btn-primary submitBtn",
    },
  ]

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  const handleSubmit = event => {
    const form = event.currentTarget;
    setButtonDisabled(true);

    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setError(fields[0].error_text)
      setShowerror(true)
      setValidated(true)
      myRef.current.scrollIntoView({ behavior: "smooth", block: "end" })

      setTimeout(function(){
        setButtonDisabled(false);
      },2000);

    } else {
      event.preventDefault()
      const formsdata = event.target
      const params = {}
      Object.keys(formsdata).map(
        key =>
          (params[formsdata[key].name] = formsdata[key].checked
            ? "yes"
            : formsdata[key].value)
      )

      if (params.role === "agents") {
        setShowerror(false)
        const handleAuth = async params => {
          const loginStatus = await handleLogin(params)
          if (loginStatus.message === "OK" && isLoggedIn()) {      
            setTimeout(function(){
              navigate(`/app-dashboard`)
            },2000);            
          } else {
            setError(loginStatus.message)
            setShowerror(true)
          }
        }
        if (!isLoggedIn()) {
          handleAuth(params)
        }
      } else {
        setError("Invalid Role!")
        setShowerror(true)
      }
      setTimeout(function(){
        setButtonDisabled(false);
      },2000);
    }
  }

  return (
  <>
    <SEO title="Login" description="Login" />

    <div className="login-wrapper">
      <div className="form-wrapper text-center">
        <Link to="#" className="logo">
          <img src={LogoImg} alt="logo-img" />
        </Link>

            <>
              <div ref={myRef}></div>
              {showerror && (
                <div className="alert-error">
                  <p>{error}</p>
                </div>
              )}
              <Form enctype="multipart/form-data"
                    name={fields[0].formname}
                    action="/thank-you/"
                    method="post"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                    data-netlify="true"
                    netlify-honeypot="bot-field"
                    data-netlify-recaptcha="true" autoComplete={false}> 

                    <input type="hidden" name="form_type" value={fields[0].form_type}/>
                    <input type="hidden" name="role" value="agents" />
                    <input type="hidden" name="bot-field" />

                    <Form.Row>
                      {fields.map((field, index) => {
                          if ("input" === field.element) {
                            return (
                              <InputField
                                name={field.name}
                                grpmd={field.grpmd}
                                label={field.label}
                                ref={field.ref}
                                type={field.type}
                                value={field.value}
                                fieldClass={field.fieldClass}
                                labelClass={field.labelClass}
                                placeholder={field.placeholder}
                                required={field.required}
                                key={`${field.element}~${index}`}
                                pattern={field.patternchk}
                                handlechange={handlechange}
                                grpCls= {field.grpCls}
                                anchorLink = {field.grpCls === "pass" && <Link to="/forgot-password">Forgot Password</Link>}
                                />
                            )
                          }
                          if ("button" === field.element) {
                            return (
                              <ButtonField
                                name={field.name}
                                fieldClass={field.class}
                                type={field.type}
                                value={field.value}
                                key={`${field.element}~${index}`}
                                button_disable = {buttonDisabled}                              
                              />
                            )
                          }
                        })}
                    </Form.Row>
                {/* <p className="form-bottom">Having trouble? <Link to="#">Get in touch with support</Link></p> */}
              </Form>
            </>


        
      </div>
      {/* form-wrapper */}
    </div>
  </>
  )

}

export default LoginPage