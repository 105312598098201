import React from 'react'
import { Button } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const ButtonField = ({type, name, value, fieldClass, grpmd, button_disable}) => (
	<div className={`col-md-${grpmd} col-sm-12 col-xs-12`}>
		{
			button_disable === true ? (
				<Button variant="" type={type} className={fieldClass} disabled>{value}</Button>
			) : (
				<Button variant="" type={type} className={fieldClass}>{value}</Button>
			)
		}
		
	</div>
);

export default ButtonField;